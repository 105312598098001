<template lang="pug">
v-container(fluid).re-filters
  portal(to="slots-type-choosing")
    v-radio-group(v-model="activeType" row hide-details).mt-0
      v-radio(:value="ACTIVE_TYPE.ALL" label="All slots")
      v-radio(:value="ACTIVE_TYPE.TOP" label="TOPCBR slots")
      v-radio(:value="ACTIVE_TYPE.MIJN" label="MIJNCBR slots")
  v-row(wrap)
    v-col(cols="12" md="3" tile)
      filter-autocomplete-loaded(
        v-model="model.filters.location"
        :getItems="getExamLocations"
        multiple
        showCloseMenuBtn
        closeOnInputClick
        label="Location"
        hideDetails
        showSelectedIcon
        testName="slots-location-input"
        :redirectName="$ROUTER_NAMES.LOGIN_CRM"
      )
    v-col(cols="12" md="3" tile)
      datepicker(
        v-model="model.filters.examDateAfter"
        hideDetails
        testName="slots-date-after"
        label="Exam date start"
      )
      datepicker(
        v-model="model.filters.examDateBefore"
        hideDetails
        testName="slots-date-before"
        label="Exam date end"
      )
    v-col(cols="12" md="3")
      datepicker(
        v-model="model.filters.examTimeAfter"
        label="Exam time start"
        icon='mdi-clock-outline'
        convertToDefault=false
        timepicker
      )
      datepicker(
        v-model="model.filters.examTimeBefore"
        label="Exam time end"
        icon='mdi-clock-outline'
        convertToDefault=false
        timepicker
      )
    v-col(cols="12" md="3")
      v-select(
        item-value="key"
        item-text="label"
        :items="model.options.weekDays.list"
        v-model="model.filters.weekDay"
        label="Week day"
        clearable
        :menu-props="{contentClass: 'menu-with-scroll-visible'}"
      )


  v-btn(outlined data-test="slots-submit" @click="filtersChanged").btn-common Apply
</template>

<script>
import {mapGetters, mapMutations, mapActions} from "vuex"
import FiltersModel, {FILTERS_ARGS} from "../core/models/slotsFiltersModel.js"
import { ACTIVE_TYPE } from '../core/slots-conts'

export default {
  data: () =>({
    model: null,
    ACTIVE_TYPE: ACTIVE_TYPE
  }),

  created() {
    this.init()
  },

  computed: {
    ...mapGetters({
      _activeType: 'slotsType/value'
    }),

    activeType: {
      get() {
        return this._activeType
      },
      set (newType) {
        this.setActiveType(newType)
        this.filtersChanged()
      }
    }
  },

  mounted() {
    this.setTypeAccordingToQuery()
    this.setDefaultExamDate()
  },

  inject: ['getDefaultPagination'],

  methods: {
    ...mapMutations({
      setActiveType: "slotsType/set"
    }),
    ...mapActions({
      getExamLocations: 'crmExamLocation/list',
      getProductNames: 'crmProductNames/list'
    }),

    init() {
      this.model = new FiltersModel(this.$route.query)
    },

    setTypeAccordingToQuery() {
      if(!this.$route.query.type) return
      this.setActiveType(this.$route.query.type)
    },

    setDefaultExamDate() {
      if(this.$route.query[FILTERS_ARGS.EXAM_DATE_AFTER]) return
      this.model.setDefaultExamDate()
    },

    async setQuery() {
      try {
        await this.$router.replace(
          {query:
            {
              ...this.$route.query,
              ...this.getDefaultPagination(),
              ...this.model.getApiData(),
              type: this.activeType !== ACTIVE_TYPE.ALL ? this.activeType : undefined
            }}
        )
      } catch (error) {
        console.log(error)
      }
    },

    async filtersChanged() {
      await this.setQuery()
      this.$emit('filterChanged')
    }
  },

  components: {
    FilterAutocomplete: () => import('@/app/admin/components/FilterAutocompleteChecked.vue'),
    Datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    FilterAutocompleteLoaded: () => import('@/components/global/FilterAutocomplete.vue'),
    Search: () => import('@/components/global/Search.vue')
  }
}
</script>

<style lang="scss" scoped>
.res-search {
  box-shadow: 0 1px 1px 0px #ccc;
}
.re-filters {
  padding-left: 14px;
  padding-right: 14px;
}

.search::v-deep {
  &.v-input .v-text-field__slot input {
    padding: 8px 0 8px;
    // padding: 0 !important;
  }
}
</style>
