import { DOW_BACKEND_ITEM_KEY } from '@/util/const';
import { convertToDefaultBackendFormat, getMultipleQueryValue } from '@/util';

export const FILTERS_ARGS = {
  LOCATION: 'location',
  EXAM_DATE_AFTER: 'exam_date_after',
  EXAM_DATE_BEFORE: 'exam_date_before',
  EXAM_TIME_AFTER: 'exam_time_after',
  EXAM_TIME_BEFORE: 'exam_time_before',
  WEEK_DAY: 'week_day'
};

export default class SlotsFiltersModel {
  constructor(filters) {
    this.filters = {
      location: getMultipleQueryValue(filters[FILTERS_ARGS.LOCATION]),
      examDateAfter: filters[FILTERS_ARGS.EXAM_DATE_AFTER],
      examDateBefore: filters[FILTERS_ARGS.EXAM_DATE_BEFORE],
      examTimeAfter: filters[FILTERS_ARGS.EXAM_TIME_AFTER],
      examTimeBefore: filters[FILTERS_ARGS.EXAM_TIME_BEFORE],
      weekDay: filters[FILTERS_ARGS.WEEK_DAY] ? +filters[FILTERS_ARGS.WEEK_DAY] : undefined
    };

    this.options = {
      weekDays: {
        list: DOW_BACKEND_ITEM_KEY
      }
    };
  }

  getApiData() {
    return {
      [FILTERS_ARGS.LOCATION]: this.filters.location,
      [FILTERS_ARGS.EXAM_DATE_BEFORE]: this.filters.examDateBefore,
      [FILTERS_ARGS.EXAM_DATE_AFTER]: this.filters.examDateAfter,
      [FILTERS_ARGS.EXAM_TIME_BEFORE]: this.filters.examTimeBefore,
      [FILTERS_ARGS.EXAM_TIME_AFTER]: this.filters.examTimeAfter,
      [FILTERS_ARGS.WEEK_DAY]: this.filters.weekDay
    };
  }

  setDefaultExamDate() {
    let now = Date.now();
    this.filters.examDateAfter = convertToDefaultBackendFormat(now);
  }
}
